import { Layout } from '@components/layout';
import { Search, Contact } from '@components/standard';
import React from 'react';

import SingleCard from './singleCard';
import { container, container__categories } from './styles/blog.module.scss';

const Blog = ({ pageContext }) => {
    const { posts, page, categories } = pageContext;

    return (
        <Layout {...page}>
            <section className={container}>
                <h1>Blog</h1>
                <h2>Dzielimy się naszą wiedzą.</h2>
                {/* <Search /> */}
                <div className={container__categories}>
                    {posts.map((post, i) => {
                        return <SingleCard {...post} />;
                    })}
                </div>
                <Contact />
            </section>
        </Layout>
    );
};
export default Blog;
