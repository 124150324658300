import { AnimatedButtonTwo, ResizedImage } from '@components/shared';
import { navigate } from 'gatsby';
import React from 'react';

import ArrowIcon from './icons/arrow';
import { truncateString } from './logic';
import {
    container__categories__single,
    container__categories__single__arrow,
} from './styles/blog.module.scss';

const SingleCard = (post) => {
    console.log(post);
    const { slug, title, seo, tags, featuredImage } = post;
    const { metaDesc } = seo;

    return (
        <AnimatedButtonTwo
            className={container__categories__single}
            onClick={() => navigate(`/blog/${slug}`)}>
            {featuredImage ? (
                <ResizedImage {...featuredImage.node} />
            ) : (
                <img alt="brak-obrazka" />
            )}
            <div>
                <h5>{title}</h5>
                <p>{metaDesc}</p>
                <div>
                    {tags.nodes.map((tag, i) => (
                        <small key={i}>{tag.name}</small>
                    ))}
                </div>
            </div>
            <ArrowIcon className={container__categories__single__arrow} />
        </AnimatedButtonTwo>
    );
};

export default SingleCard;
