import { IIconProps } from 'components.refactored/standard/types';
import React from 'react';

interface IArrowIcon extends IIconProps {
    onClick?: () => any;
}

const ArrowIcon = ({ className, onClick }: IArrowIcon) => {
    return (
        <svg
            width="11"
            height="16"
            viewBox="0 0 11 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}>
            <path
                d="M10.547 14.12L4.44033 8L10.547 1.88L8.66699 0L0.666992 8L8.66699 16L10.547 14.12Z"
                fill="#323232"
            />
        </svg>
    );
};

export default ArrowIcon;
